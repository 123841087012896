import * as React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import ImageMosaic from './ImageMosaic';
import "./Layout.css";


const Layout: React.FC<PageProps> = ({ children, pageResources }) => {
    
    const query = useStaticQuery(graphql`
        query LogoQuery {
            file(base: { eq: "sh.svg" }) {
                publicURL
            }
        }
    `);

    return (<>
        <ImageMosaic />
        {children}
        <main className="Layout__title-card">
            <header>
                <span className="Layout__white-bg Layout__title">[SH]2023</span>
            </header>
            <footer className="Layout__horizontal-split">
                <div className="Layout__logo"><img src={query.file.publicURL} width="48" height="39" /></div>
                <div><span className="Layout__white-bg">Photography by Kevin Lee. &copy;2023 ALL RIGHTS RESERVED. <a href="mailto:photography@subhype.net" >Inquiries</a></span></div>
            </footer>
        </main>
    </>);
};

export default Layout;
