import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage} from "gatsby-plugin-image";
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';

import "./ImageMosaic.css";



const getThumbsize = (width: number, height: number): number[] => {
    const ratio = width / height;
    const y = Math.sqrt(1 / (1 + (ratio * ratio)));
    const x = ratio * y;
    return [x, y];
};

interface ImageMosaicProps {
    photos?: any;
}
const ImageMosaic: FC<RouteComponentProps<ImageMosaicProps>> = ({ photos }) => {
    const minimumImageSize = 450;
    const [imageSize, setImageSize] = useState<number>(minimumImageSize);
    const thumbs = useStaticQuery(graphql`
        query ThumbsQuery {
            allFile(filter: { sourceInstanceName: { eq: "content" }, dir: {regex: "/photos$/"} }) {
                edges {
                  node {
                    id
                    name
                    childImageSharp {
                      id
                      gatsbyImageData (width: 400, placeholder: NONE)
                    }
                  }
                }
              }
        }
    `);
    photos = thumbs.allFile;
    const containerElement = useRef<HTMLElement>();
    useLayoutEffect(() => {
        const fontSize = global
            .getComputedStyle(containerElement.current, null)
            .getPropertyValue("font-size")
            .replace(/^([0-9]+).*$/g, '$1');
        setImageSize(Math.min(minimumImageSize, (containerElement.current?.clientWidth - (Number(fontSize) * 2)) * 1.20));
    }, []);
    console.log(imageSize);
    return <div className="ImageMosaic__container" ref={containerElement}>
        {
            photos.edges.map((edge: any) => {
                if (!edge?.node?.childImageSharp) { return null; }
                const ratios = getThumbsize(edge.node.childImageSharp.gatsbyImageData.width, edge.node.childImageSharp.gatsbyImageData.height);
                return (
                    <div className="ImageMosaic__item" key={edge.node.name} style={{ width: ratios[0] * imageSize, height: ratios[1] * imageSize }}>
                        <Link to={`/photo/${edge.node.name}`}>
                            <GatsbyImage
                                backgroundColor="white"
                                image={edge?.node?.childImageSharp?.gatsbyImageData}
                                alt={edge.node.name}
                                imgStyle={{ objectFit: 'scale-down', objectPosition: 'center' }}
                            />
                        </Link>
                    </div>
                );
            })
        }
    </div>;
};
export default ImageMosaic;
